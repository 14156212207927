import { useQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ColorThief from 'colorthief';
import gql from 'graphql-tag';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import FlipMove from 'react-flip-move';
import NumberEasing from 'react-number-easing';

//@ts-ignore
const colorThief = new ColorThief();

const GET_STATS = gql`
  query GetStats(
    $type: String!
    $time: String!
    $ergTypes: [String]
    $limit: Int
  ) {
    stats: recentStats(
      type: $type
      time: $time
      ergTypes: $ergTypes
      limit: $limit
    ) {
      name
      image
      total
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(2),
    },
    splitContainer: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    formLabel: {
      color: 'white',
      ...theme.typography.h6,
    },
    filterContainer: {
      width: 228,
      marginTop: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-evenly',
      },
    },
    rowsContainer: {
      flex: 1,
      position: 'relative',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    row: {
      display: 'flex',
      margin: theme.spacing(1),
    },
    bar: {
      display: 'flex',
      flexShrink: 0,
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
      minWidth: theme.spacing(6),
      justifyContent: 'flex-end',
      transition: 'width 0.4s',
      boxShadow:
        'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px',
    },
    image: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      objectFit: 'cover',
      borderRadius: '50%',
      boxShadow:
        'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px',
    },
    rowMetricContainer: {
      minWidth: 0,
      marginLeft: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    rowName: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 700,
    },
    loadMore: {
      marginTop: theme.spacing(4),
      color: 'white',
      borderColor: 'white',
    },
  })
);

interface Stat {
  name: string;
  total: number;
  row: number;
  ski: number;
  multi: number;
  bike: number;
  image: string;
}

const POLL_INTERVAL = 10000;
const ITEMS_PER_PAGE = 10;

const Row = forwardRef(
  ({ stat, percentage }: { stat: Stat; percentage: number }, ref) => {
    const styles = useStyles();
    const [color, setColor] = useState('#cccccc0f');
    const imageRef = useRef();

    const onLoad = useCallback(() => {
      if (imageRef.current) {
        const newColor = colorThief.getPalette(imageRef.current)[0];
        setColor(`rgba(${newColor[0]}, ${newColor[1]}, ${newColor[2]}, 0.9)`);
      }
    }, [setColor, imageRef.current]);

    return (
      <div
        className={styles.row}
        // @ts-ignore
        ref={ref}
      >
        <div
          className={styles.bar}
          style={{ backgroundColor: color, width: `${80 * percentage}%` }}
        >
          <img
            ref={imageRef}
            className={styles.image}
            src={stat.image}
            onLoad={onLoad}
            crossOrigin="anonymous"
          />
        </div>
        <div className={styles.rowMetricContainer}>
          <div className={styles.rowName}>{stat.name}</div>
          <NumberEasing
            value={stat.total}
            speed={400}
            decimals={0}
            ease="quintInOut"
            customFunctionRender={(value) =>
              value.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
          />
          m
        </div>
      </div>
    );
  }
);

const defaultErgs = ['row', 'ski', 'bike', 'multi'];

const RecentStats = () => {
  const styles = useStyles();
  const [type, setType] = useState('group');
  const [time, setTime] = useState('1-day');
  const [ergTypes, setErgTypes] = useState(defaultErgs);
  const [stats, setStats] = useState([]);
  const [page, setPage] = useState(1);

  const variables = {
    type,
    time,
    ergTypes: ergTypes.length === 0 ? defaultErgs : ergTypes,
    limit: page * ITEMS_PER_PAGE + 1,
  };

  const { loading } = useQuery(GET_STATS, {
    fetchPolicy: 'network-only',
    variables,
    pollInterval: POLL_INTERVAL,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setStats(data && data.stats ? data.stats : []);
    },
  });

  const onErgChange = (event) => {
    const erg = event.target.name;
    ergTypes.includes(erg)
      ? setErgTypes(ergTypes.filter((t) => t != erg))
      : setErgTypes([...ergTypes, erg]);
  };

  const firstMetric = stats[0] ? stats[0].total : 1;

  return (
    <div className={styles.container}>
      <h4>Recent Stats</h4>
      <div className={styles.splitContainer}>
        <div className={styles.filterContainer}>
          <div>
            <ButtonGroup color="primary">
              <Button
                style={{ flex: 1 }}
                variant={type === 'group' ? 'contained' : 'outlined'}
                onClick={() => setType('group')}
              >
                Groups
              </Button>
              <Button
                style={{ flex: 1 }}
                variant={type === 'country' ? 'contained' : 'outlined'}
                onClick={() => setType('country')}
              >
                Countries
              </Button>
            </ButtonGroup>

            <FormGroup style={{ marginTop: 10, marginBottom: 10 }}>
              <FormLabel component="legend" className={styles.formLabel}>
                Erg
              </FormLabel>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={ergTypes.includes('row')}
                    onChange={onErgChange}
                    name="row"
                  />
                }
                label="Row"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ergTypes.includes('ski')}
                    onChange={onErgChange}
                    name="ski"
                  />
                }
                label="Ski"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ergTypes.includes('bike')}
                    onChange={onErgChange}
                    name="bike"
                  />
                }
                label="Bike"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ergTypes.includes('multi')}
                    onChange={onErgChange}
                    name="multi"
                  />
                }
                label="Multi"
              />
            </FormGroup>
          </div>

          <RadioGroup value={time} onChange={(_, value) => setTime(value)}>
            <FormLabel component="legend" className={styles.formLabel}>
              In the last
            </FormLabel>
            <FormControlLabel value="1-day" control={<Radio />} label="1 day" />
            <FormControlLabel
              value="7-day"
              control={<Radio />}
              label="7 days "
            />
            <FormControlLabel
              value="30-day"
              control={<Radio />}
              label="30 days"
            />
          </RadioGroup>
        </div>

        <div className={styles.rowsContainer}>
          {!loading && stats.length === 0 && (
            <div style={{ textAlign: 'center' }}>No data for this criteria</div>
          )}
          <FlipMove typeName={null} duration={400}>
            {stats
              .slice(0, Math.min(stats.length, page * ITEMS_PER_PAGE))
              .map((stat) => (
                <Row
                  key={stat.name}
                  stat={stat}
                  percentage={stat.total / firstMetric}
                />
              ))}
          </FlipMove>
          {stats.length === page * ITEMS_PER_PAGE + 1 && (
            <Button
              className={styles.loadMore}
              variant="outlined"
              onClick={() => setPage((value) => value + 1)}
            >
              Load More
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecentStats;
