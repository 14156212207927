import React, { useState, useEffect } from 'react';

import content from '../content/testimonials.yml';

const Testimonials = () => {
  const [visibleIndex, setVisibleIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleIndex((index) => (index + 1) % content.quotes.length);
    }, 8000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div id="testimonials">
      {content.quotes.map((quote, index) => (
        <blockquote
          key={index}
          style={{ display: visibleIndex === index ? 'block' : 'none' }}
        >
          <p>{quote.text}</p>
          <cite>— {quote.cite}</cite>
        </blockquote>
      ))}
    </div>
  );
};

export default Testimonials;
