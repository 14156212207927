import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  '@global': {
    h6: {
      fontFamily: 'Nunito',
    },
    h4: {
      fontFamily: 'Nunito',
    },
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  cardHeader: {
    backgroundColor: colors.primaryColor,
    color: '#ffffff',
    fontFamily: 'Nunito',
    fontWeight: '200',
  },
  cardAnnualPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    fontFamily: 'Nunito',
    color: colors.leaderboardColor,
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    fontFamily: 'Nunito',
    color: colors.leaderboardColor,
  },
  freeInfo: {
    marginBottom: theme.spacing(1),
    color: colors.secondaryColor,
    fontFamily: 'Nunito',
    fontWeight: '200',
  },
  trackInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 6,
    marginBottom: theme.spacing(2),
    color: colors.white,
    fontFamily: 'Muli',
    fontWeight: '200',
  },
  smallInfo: {
    alignItems: 'baseline',
    color: colors.white,
    fontFamily: 'Muli',
    fontWeight: '100',
    padding: 3,
  },
  faqInfo: {
    textAlign: 'left',
    fontFamily: 'Muli',
    fontWeight: '100',
    color: '#a3adb5',
    padding: 0,
    margin: 0,
  },
  orInfo: {
    fontFamily: 'Muli',
    fontWeight: '100',
    color: 'black',
    marginLeft: 12,
    marginRight: 12,
  },
}));

const colors = {
  primaryColor: '#009e9a',
  secondaryColor: '#00C9BE',
  tertiaryColor: '#d9915e',
  destructiveColor: '#9e0003',
  leaderboardColor: '#c9006b',
  membershipColor: '#44459A',
  linkColor: '#2980b9',
  textColorDark: 'rgba(0,0,0, 0.87)',
  textColor: 'rgba(0,0,0, 0.54)',
  overlayBg: 'rgba(0,0,0, 0.2)',
  headerColor: 'rgba(255,255,255, 0.54)',
  dividerColor: '#ccc',
  white: '#ffffff',
  black: '#000000',
  background: '#ccc',
  lightGray: '#f9f9f9f9',
  darkBackground: '#2b292a',
};
const tiers1 = [
  {
    title: 'Tier 1',
    price: '5',
    price2: '50',
    description: ['Up To 2 Members'],
  },
  {
    title: 'Tier 2',
    price: '9',
    price2: '90',
    description: ['Up To 5 Members'],
  },
  {
    title: 'Tier 3',
    price: '15',
    price2: '150',
    description: ['Up To 10 Members'],
  },
  {
    title: 'Tier 4',
    price: '35',
    price2: '350',
    description: ['Up To 25 Members'],
  },
  {
    title: 'Tier 5',
    price: '55',
    price2: '550',
    description: ['Up To 50 Members'],
  },
  {
    title: 'Tier 6',
    price: '85',
    price2: '850',
    description: ['Up To 100 Members '],
  },
  {
    title: 'Tier 7',
    price: '115',
    price2: '1150',
    description: ['Up To 175 Members '],
  },
  {
    title: 'Tier 8',
    price: '145',
    price2: '1450',
    description: ['Up To 250 Members '],
  },
];
export default function Pricing() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container maxWidth="md" component="main">
        <h2>Pricing</h2>
        <h2>For Athletes</h2>
        <div className={classes.trackInfo}>
          <div style={{ alignSelf: 'center' }}>
            <ul style={{ textAlign: 'left', maxWidth: 700 }}>
              <li>
                The free version of the ErgZone app offers a variety of tools to
                simplify and enhance your training. Go beyond with the ErgZone+
                in-app subscription to unlock extra features, taking your
                training experience to the next level.
              </li>
            </ul>
          </div>
        </div>
        <Grid container spacing={1} justify="center" alignItems="stretch">
          {[
            {
              title: 'Free',
              subheader: '',
              prices: [{ value: '0' }],
              description: [
                'Create unlimited workouts',
                'Concept2 Logbook integration',
                'Set targets for pace & rate',
                'Benchmarks for 2k/4k and others',
                'Real-Time Metrics',
                'Heart Rate Zones',
                'Table & Grid metrics view',
                'Weekly samples from coaches',
                'Bookmark favorite workouts',
                'Estimation for workout duration',
                'Rankings for official workouts ',
                'Free ErgZone Challenges',
              ],
              link: { name: 'Download', url: '#download' },
            },
            {
              title: 'ErgZone+',
              subheader: '',
              prices: [
                { value: '4.99', recurring: '/mo' },
                { value: '49.99', recurring: '/yr' },
              ],
              description: [
                'All features from Free',
                'Force Curve & Additional Metrics',
                'MultiErg & Fit Workouts',
                'Graph: Heart Rate & Power',
                'Apple Watch & Health Integration',
                'ReRow, ReSki, ReRide',
                'Web Admin on computer',
                'Time Cap & AMRAP',
                'Power Intensity, FTP, SPI',
                'Create & Join Communities',
                '7-day trial for monthly plan',
                '2-month discount for yearly plan',
              ],
              link: {
                name: 'Learn More',
                url: 'https://help.erg.zone/article/153-what-is-ergzone-plus',
              },
            },
          ].map((tier) => (
            <Grid item key={tier.title} xs={12} md={5}>
              <Card style={{ height: '100%' }}>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardAnnualPricing}>
                    {tier.prices.map((price, i) => (
                      <>
                        {i > 0 && <span className={classes.orInfo}>or</span>}
                        <Typography component="h3" variant="h4">
                          ${price.value}
                        </Typography>
                        <Typography variant="h6" color="textSecondary">
                          {price.recurring}
                        </Typography>
                      </>
                    ))}
                  </div>
                  <ul className="text-left ml-4 mt-2">
                    {tier.description.map((line, i) => (
                      <li key={line}>
                        <i class="fas fa-check mr-2"></i>
                        {line}
                      </li>
                    ))}
                  </ul>
                </CardContent>
                <a href={tier.link.url} class="btn btn-solid btn-xl mb-4">
                  {tier.link.name}
                </a>
              </Card>
            </Grid>
          ))}
        </Grid>
        <h2 style={{ marginTop: 40 }} id="group-pricing">
          For Groups / Teams
        </h2>
        <div className={classes.trackInfo}>
          <h3>Unlock extra features with a Group paid plan:</h3>
          <div style={{ alignSelf: 'center' }}>
            <ul style={{ textAlign: 'left' }}>
              <li>
                • Share workouts with the team, gym members, clients, friends,
                and family
              </li>
              <li>
                • Manage workouts and members from a mobile device and our Web
                Platform
              </li>
              <li>• Enable workout leaderboards</li>
              <li>• In-App Chat with members</li>
              <li>
                • Live Dashboard with real-time metrics for gyms, clubs, remote
                coaching. Watch it in action{' '}
                <a
                  href="https://www.youtube.com/watch?v=7BPchvf79iY&list=PLGOnOjck2QBzSDKCsK05mX-5sPs-mfcDK"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  here
                </a>
                .
              </li>
            </ul>
          </div>
        </div>
        <Grid container spacing={1} justify="center" alignItems="stretch">
          {tiers1.map((tier) => (
            <Grid item key={tier.title} xs={12} md={3}>
              <a href="https://admin.erg.zone/billing">
                <Card style={{ height: '100%' }}>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    className={classes.cardHeader}
                  />
                  <CardContent>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography align="center" key={line}>
                          {line}
                        </Typography>
                      ))}
                    </ul>
                    <div className={classes.cardAnnualPricing}>
                      <Typography component="h3" variant="h4">
                        ${tier.price}
                      </Typography>
                      <Typography variant="h6" color="textSecondary">
                        /mo
                      </Typography>
                    </div>
                    <Typography variant="body2" className={classes.small}>
                      or get 2 months free
                    </Typography>
                    <Typography variant="body1">${tier.price2}/yr</Typography>
                  </CardContent>
                </Card>
              </a>
            </Grid>
          ))}
        </Grid>
        <Typography className={classes.smallInfo}>
          No contracts, cancel anytime.
        </Typography>
        <Typography className={classes.smallInfo}>
          Additional taxes may apply based on your location.
        </Typography>
        <Typography className={classes.smallInfo}>
          More than 250 Members: +$30/mo per 100 additional members.
        </Typography>
        <Typography className={classes.smallInfo}>
          Questions? Please see our{' '}
          <a href="https://help.erg.zone/article/255-group-faq">
            Group Pricing FAQ
          </a>
          .
        </Typography>
      </Container>
    </React.Fragment>
  );
}
