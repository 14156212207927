import { Link } from 'gatsby';
import React, { Component } from 'react';
import config from '../../config';
import Scroll from './Scroll';
export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      visibilityClass: '',
    };
  }
  toggleMenu = (value) => {
    this.setState({ openMenu: value });
  };

  handleScroll = () => {
    const { visibilityClass } = this.state;
    if (window.pageYOffset > 40) {
      if (visibilityClass !== 'navbar-shrink') {
        this.setState({ visibilityClass: 'navbar-shrink' });
      }
    } else {
      if (visibilityClass === 'navbar-shrink') {
        this.setState({ visibilityClass: '' });
      }
    }
  };
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { openMenu, visibilityClass } = this.state;
    return (
      <nav
        className={`navbar navbar-expand-lg navbar-light fixed-top ${visibilityClass}`}
        id="mainNav"
      >
        <div className="container">
          <Scroll
            onClick={(_) => this.toggleMenu(!openMenu)}
            type="id"
            element="top"
          >
            <a className="navbar-brand" href="#top">
              {config.siteTitle}
            </a>
          </Scroll>
          <button
            onClick={(_) => this.toggleMenu(!openMenu)}
            className={`navbar-toggler navbar-toggler-right ${
              openMenu ? '' : 'collapsed'
            }`}
            type="button"
            aria-controls="navbarResponsive"
            aria-expanded={openMenu}
            aria-label="Toggle navigation"
          >
            Menu <i className="fas fa-bars"></i>
          </button>

          <div
            className={`collapse navbar-collapse ${openMenu ? 'show' : ''}`}
            id="navbarResponsive"
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Scroll
                  onClick={(_) => this.toggleMenu(!openMenu)}
                  type="id"
                  element="pricing"
                >
                  <a className="nav-link" href="#pricing">
                    Pricing
                  </a>
                </Scroll>
              </li>
              <li className="nav-item">
                <Link to="/discover" className="nav-link">
                  Discover
                </Link>
              </li>
              <li className="nav-item">
                <a href="https://help.erg.zone/" className="nav-link">
                  FAQ
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#contact"
                  onClick={(e) => {
                    window.Beacon('navigate', '/ask/message');
                    window.Beacon('open');
                  }}
                  className="nav-link"
                >
                  Contact
                </a>
              </li>
              <li className="nav-item">
                <a href="https://admin.erg.zone/" className="nav-link">
                  Login
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
